import axios from 'axios';
import axiosRetry from 'axios-retry';
import { API_CONFIG } from './config';

// Create axios instance with retry configuration
const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Token': API_CONFIG.TOKEN,
    'Accept': 'application/json'
  },
  timeout: 30000 // 30 second timeout
});

// Configure retry behavior
axiosRetry(api, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000; // Wait 1s, 2s, 3s between retries
  },
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || 
           (error.response?.status ? error.response.status >= 500 : false);
  }
});

// Add request interceptor to ensure token is always sent
api.interceptors.request.use((config) => {
  config.headers.Token = API_CONFIG.TOKEN;
  return config;
});

// Add response interceptor for error handling
api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      config: {
        url: error.config?.url,
        method: error.config?.method,
        headers: error.config?.headers
      }
    });

    if (error.code === 'ECONNABORTED') {
      throw new Error('Tempo limite de conexão excedido. Por favor, tente novamente.');
    }

    if (!error.response) {
      throw new Error('Erro de conexão. Verifique sua internet e tente novamente.');
    }

    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }

    throw new Error('Erro ao processar solicitação. Por favor, tente novamente.');
  }
);

// Recharge
export interface RechargeResponse {
  status: number;
  url?: string;
  link_checkout?: string;
  payment_url?: string;
  id_boleto?: number;
  copia_cola?: string;
  base64?: string;
  message?: string;
  error?: string;
}

export const processRecharge = async (phone: string, planId: string): Promise<RechargeResponse> => {
  try {
    const cleanPhone = phone.replace(/\D/g, '');
    if (cleanPhone.length !== 11) {
      throw new Error('Número de telefone inválido');
    }

    const response = await api.post<RechargeResponse>(`${API_CONFIG.ENDPOINTS.BASE_URL}${API_CONFIG.ENDPOINTS.RECHARGE}`, {
      telefone: cleanPhone,
      id_plano: planId
    });

    // Validate response
    if (!response.data) {
      throw new Error('Resposta inválida da API');
    }

    // Check for error in response
    if (response.data.error) {
      throw new Error(response.data.error);
    }

    // Check for payment URL in any of the possible fields
    const paymentUrl = response.data.url || response.data.link_checkout || response.data.payment_url;
    if (!paymentUrl) {
      throw new Error('URL de pagamento não encontrada na resposta');
    }

    return {
      ...response.data,
      link_checkout: paymentUrl // Ensure we always return the URL in link_checkout field
    };
  } catch (error) {
    console.error('Error processing recharge:', {
      error,
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : undefined
    });

    if (error instanceof Error) {
      throw error;
    }

    throw new Error('Erro ao processar recarga. Por favor, tente novamente.');
  }
};

// Chip Request
export interface ChipRequestResponse {
  status?: number;
  copia_cola?: string;
  base64?: string;
  link_checkout?: string;
  payment_url?: string;
  url?: string;
  message?: string;
  id_boleto?: number;
  error?: string;
  id?: string;
}

export const processChipRequest = async (data: {
  tipo: 'físico' | 'e-sim';
  cpf_cnpj: string;
  nome_completo: string;
  logradouro: string;
  cidade: string;
  cep: string;
  uf: string;
  telefone: string;
  email: string;
  cupom?: string;
  forma_pagto: 'C';
  qtde: number;
}): Promise<ChipRequestResponse> => {
  try {
    // Validate required fields
    if (!data.cpf_cnpj || !data.nome_completo || !data.logradouro || !data.cidade || 
        !data.cep || !data.uf || !data.telefone || !data.email) {
      throw new Error('Todos os campos obrigatórios devem ser preenchidos');
    }

    // Clean up phone and CEP
    const cleanData = {
      ...data,
      telefone: data.telefone.replace(/\D/g, ''),
      cep: data.cep.replace(/\D/g, '')
    };

    const response = await api.post<ChipRequestResponse>(
      `${API_CONFIG.ENDPOINTS.BASE_URL}${API_CONFIG.ENDPOINTS.CHIP_REQUEST}`,
      cleanData
    );

    if (!response.data) {
      throw new Error('Resposta inválida do servidor');
    }

    if (response.data.error) {
      throw new Error(response.data.error);
    }

    // Check for payment URL in any of the possible fields and normalize it
    const paymentUrl = response.data.payment_url || response.data.link_checkout || response.data.url;
    if (!paymentUrl) {
      console.error('Payment URL missing from response:', response.data);
      throw new Error('URL de pagamento não encontrada na resposta. Por favor, entre em contato com o suporte.');
    }

    // Return normalized response with consistent payment_url field
    return {
      ...response.data,
      payment_url: paymentUrl,
      id: response.data.id_boleto?.toString() || response.data.id || 'N/A'
    };
  } catch (error) {
    console.error('Error requesting chip:', error);
    
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || 'Erro ao solicitar chip. Por favor, tente novamente.';
      throw new Error(errorMessage);
    }
    
    if (error instanceof Error) {
      throw error;
    }
    
    throw new Error('Erro ao processar solicitação. Por favor, tente novamente.');
  }
};

// Contact Form
export const sendContactForm = async (data: {
  nome_completo: string;
  email: string;
  assunto: string;
  mensagem: string;
  telefone: string;
}) => {
  try {
    const response = await api.post(`${API_CONFIG.ENDPOINTS.BASE_URL}${API_CONFIG.ENDPOINTS.CONTACT}`, data);
    return response.data;
  } catch (error) {
    console.error('Error sending contact form:', error);
    throw new Error('Erro ao enviar mensagem. Por favor, tente novamente.');
  }
};

// Business Contact Form
export const sendBusinessForm = async (data: {
  nome_completo: string;
  email: string;
  telefone: string;
  empresa: string;
  funcionarios: string;
  mensagem: string;
}) => {
  try {
    const response = await api.post(`${API_CONFIG.ENDPOINTS.BASE_URL}${API_CONFIG.ENDPOINTS.CONTACT}`, {
      ...data,
      assunto: 'Contato Empresarial'
    });
    return response.data;
  } catch (error) {
    console.error('Error sending business form:', error);
    throw new Error('Erro ao enviar solicitação. Por favor, tente novamente.');
  }
};

// Utility - CEP Lookup
export const fetchAddressByCep = async (cep: string) => {
  try {
    const cleanCep = cep.replace(/\D/g, '');
    if (cleanCep.length !== 8) {
      throw new Error('CEP inválido');
    }

    const response = await axios.get(API_CONFIG.ENDPOINTS.CEP.replace(':cep', cleanCep), {
      timeout: 5000 // 5 second timeout for CEP lookup
    });

    if (response.data.erro) {
      throw new Error('CEP não encontrado');
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching CEP:', error);
    if (axios.isAxiosError(error)) {
      if (error.code === 'ECONNABORTED') {
        throw new Error('Tempo limite excedido ao buscar CEP');
      }
      throw new Error('Erro ao buscar CEP. Por favor, tente novamente.');
    }
    throw error;
  }
};