import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useScrollToTop } from './lib/hooks/useScrollToTop';
import { useLocation as useGeoLocation } from './lib/hooks/useLocation';
import { useAuth } from './lib/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import InstallBanner from './components/InstallBanner';
import Home from './pages/Home';
import Business from './pages/Business';
import About from './pages/About';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Stores from './pages/Stores';
import RequestChip from './pages/RequestChip';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import EsimGuide from './pages/EsimGuide';
import BecomeReseller from './pages/BecomeReseller';
import Vendors from './pages/Vendors';
import EsimSales from './pages/EsimSales';
import EsimVerification from './pages/EsimVerification';

// App Routes
import AppLayout from './components/Layout';
import AdminLayout from './components/AdminLayout';
import AppLogin from './pages/app/Login';
import AppRecharge from './pages/app/Recharge';
import AppHistory from './pages/app/History';
import AppSettings from './pages/app/Settings';
import AppNotifications from './pages/app/settings/Notifications';
import AppPrivacy from './pages/app/settings/Privacy';
import AppSupport from './pages/app/settings/Support';
import AppAbout from './pages/app/settings/About';
import AppProfile from './pages/app/settings/Profile';

// Admin Routes
import AdminDashboard from './pages/app/admin/Dashboard';
import AdminUsers from './pages/app/admin/Users';
import AdminRecharges from './pages/app/admin/Recharges';
import AdminReports from './pages/app/admin/Reports';
import AdminStats from './pages/app/admin/Stats';
import AdminNotifications from './pages/app/admin/Notifications';
import AdminVendors from './pages/app/admin/Vendors';
import AdminSettings from './pages/app/admin/Settings';

// Protected Route Component
const ProtectedRoute = ({ children, requireAdmin = false }: { children: React.ReactNode, requireAdmin?: boolean }) => {
  const { isAuthenticated, isAdmin } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/app/login" state={{ from: location }} replace />;
  }

  if (requireAdmin && !isAdmin) {
    return <Navigate to="/app/recharge" replace />;
  }

  return <>{children}</>;
};

function App() {
  const { city } = useGeoLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const location = useLocation();

  // Use the scroll to top hook
  useScrollToTop();

  // Check if we're in the app routes
  const isAppRoute = location.pathname.startsWith('/app');

  if (isAppRoute) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Toaster position="top-right" />
        <InstallBanner />
        <Routes>
          <Route path="/app/login" element={<AppLogin />} />
          
          {/* Regular User Routes */}
          <Route path="/app" element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }>
            <Route index element={<Navigate to="/app/recharge" replace />} />
            <Route path="recharge" element={<AppRecharge />} />
            <Route path="history" element={<AppHistory />} />
            <Route path="settings" element={<AppSettings />} />
            <Route path="settings/profile" element={<AppProfile />} />
            <Route path="settings/notifications" element={<AppNotifications />} />
            <Route path="settings/privacy" element={<AppPrivacy />} />
            <Route path="settings/support" element={<AppSupport />} />
            <Route path="settings/about" element={<AppAbout />} />
          </Route>

          {/* Admin Routes */}
          <Route path="/app/admin" element={
            <ProtectedRoute requireAdmin>
              <AdminLayout />
            </ProtectedRoute>
          }>
            <Route index element={<Navigate to="/app/recharge" replace />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="recharges" element={<AdminRecharges />} />
            <Route path="reports" element={<AdminReports />} />
            <Route path="stats" element={<AdminStats />} />
            <Route path="notifications" element={<AdminNotifications />} />
            <Route path="vendors" element={<AdminVendors />} />
            <Route path="settings" element={<AdminSettings />} />
          </Route>
        </Routes>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Toaster position="top-right" />
      <InstallBanner />
      <Header 
        city={city} 
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/empresa" element={<Business />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/lojas" element={<Stores />} />
          <Route path="/solicitar-chip" element={<RequestChip />} />
          <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
          <Route path="/termos-e-condicoes" element={<Terms />} />
          <Route path="/ativar-esim" element={<EsimGuide />} />
          <Route path="/seja-revendedor" element={<BecomeReseller />} />
          <Route path="/vendedores" element={<Vendors />} />
          <Route path="/esim" element={<EsimSales />} />
          <Route path="/verificar-esim" element={<EsimVerification />} />
          {/* Redirect /recarga to app */}
          <Route path="/recarga" element={<Navigate to="/app/recharge" replace />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;