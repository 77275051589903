import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Smartphone, QrCode, ArrowRight, ArrowLeft, Loader2, Search, CheckCircle, ExternalLink } from 'lucide-react';
import toast from 'react-hot-toast';
import { IMaskInput } from 'react-imask';
import { processChipRequest, fetchAddressByCep } from '../../lib/api';
import { useLocation } from '../../lib/hooks/useLocation';

interface ChipRequestFormProps {
  onSuccess?: () => void;
}

interface FormData {
  tipo: 'físico' | 'e-sim';
  cpf_cnpj: string;
  nome_completo: string;
  logradouro: string;
  numero: string;
  complemento: string;
  cidade: string;
  cep: string;
  uf: string;
  telefone: string;
  email: string;
  cupom: string;
  forma_pagto: 'C';
  qtde: number;
}

interface OrderConfirmation {
  payment_url: string;
  order_id: string;
  total: number;
}

const steps = [
  {
    id: 'tipo',
    title: 'Tipo de Chip',
    subtitle: 'Escolha entre chip físico ou e-SIM'
  },
  {
    id: 'identificacao',
    title: 'Identificação',
    subtitle: 'Seus dados pessoais'
  },
  {
    id: 'endereco',
    title: 'Endereço',
    subtitle: 'Para entrega do chip'
  },
  {
    id: 'contato',
    title: 'Contato',
    subtitle: 'Suas informações de contato'
  },
  {
    id: 'revisao',
    title: 'Revisão',
    subtitle: 'Confirme seus dados'
  },
  {
    id: 'confirmacao',
    title: 'Confirmação',
    subtitle: 'Pedido realizado'
  }
];

const ChipRequestForm: React.FC<ChipRequestFormProps> = ({ onSuccess }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [orderConfirmation, setOrderConfirmation] = useState<OrderConfirmation | null>(null);
  const { city } = useLocation();
  
  const [formData, setFormData] = useState<FormData>({
    tipo: 'físico',
    cpf_cnpj: '',
    nome_completo: '',
    logradouro: '',
    numero: '',
    complemento: '',
    cidade: city || '',
    cep: '',
    uf: '',
    telefone: '',
    email: '',
    cupom: '',
    forma_pagto: 'C',
    qtde: 1
  });

  const updateField = (field: keyof FormData, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleCepBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const cep = e.target.value.replace(/\D/g, '');
    if (cep.length !== 8) return;

    setLoadingCep(true);
    try {
      const address = await fetchAddressByCep(cep);
      setFormData(prev => ({
        ...prev,
        logradouro: address.logradouro || '',
        cidade: address.localidade || '',
        uf: address.uf || ''
      }));
    } catch (error) {
      console.error('Error fetching CEP:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao buscar CEP');
    } finally {
      setLoadingCep(false);
    }
  };

  const validateForm = () => {
    if (!formData.cpf_cnpj || formData.cpf_cnpj.length < 11) {
      throw new Error('CPF/CNPJ inválido');
    }
    if (!formData.nome_completo) {
      throw new Error('Nome completo é obrigatório');
    }
    if (!formData.logradouro || !formData.numero) {
      throw new Error('Endereço completo é obrigatório');
    }
    if (!formData.cidade || !formData.uf) {
      throw new Error('Cidade e Estado são obrigatórios');
    }
    if (!formData.cep || formData.cep.replace(/\D/g, '').length !== 8) {
      throw new Error('CEP inválido');
    }
    if (!formData.telefone || formData.telefone.replace(/\D/g, '').length < 10) {
      throw new Error('Telefone inválido');
    }
    if (!formData.email || !formData.email.includes('@')) {
      throw new Error('Email inválido');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Validate form data
      validateForm();

      // Prepare data for submission
      const cleanData = {
        ...formData,
        logradouro: `${formData.logradouro}, ${formData.numero}${formData.complemento ? ` - ${formData.complemento}` : ''}`
      };

      const response = await processChipRequest(cleanData);
      
      // Check for payment URL in the normalized response
      if (!response.payment_url) {
        throw new Error('URL de pagamento não encontrada na resposta. Por favor, entre em contato com o suporte.');
      }

      setOrderConfirmation({
        payment_url: response.payment_url,
        order_id: response.id || 'N/A',
        total: formData.tipo === 'físico' ? 29.90 : 19.90
      });
      
      // Move to confirmation step
      nextStep();
      
      // Call onSuccess callback if provided
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao processar solicitação');
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="space-y-8">
            <div className="grid grid-cols-2 gap-6">
              <button
                type="button"
                onClick={() => {
                  updateField('tipo', 'físico');
                  nextStep();
                }}
                className={`p-8 rounded-2xl border-2 flex flex-col items-center justify-center gap-4 transition-all duration-300 hover:shadow-lg ${
                  formData.tipo === 'físico'
                    ? 'border-uai-teal bg-uai-light'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <Smartphone className="w-16 h-16 text-uai-teal" />
                <div className="text-center">
                  <h3 className="text-xl font-bold mb-2">Chip Físico</h3>
                  <p className="text-gray-600">Receba em casa ou retire em uma loja</p>
                </div>
              </button>

              <button
                type="button"
                onClick={() => {
                  updateField('tipo', 'e-sim');
                  nextStep();
                }}
                className={`p-8 rounded-2xl border-2 flex flex-col items-center justify-center gap-4 transition-all duration-300 hover:shadow-lg ${
                  formData.tipo === 'e-sim'
                    ? 'border-uai-teal bg-uai-light'
                    : 'border-gray-200 hover:border-gray-300'
                }`}
              >
                <QrCode className="w-16 h-16 text-uai-teal" />
                <div className="text-center">
                  <h3 className="text-xl font-bold mb-2">e-SIM</h3>
                  <p className="text-gray-600">Ative instantaneamente no seu celular</p>
                </div>
              </button>
            </div>
          </div>
        );

      case 1:
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                CPF/CNPJ
              </label>
              <IMaskInput
                mask="000.000.000-00"
                value={formData.cpf_cnpj}
                unmask={true}
                onAccept={(value) => setFormData({ ...formData, cpf_cnpj: value })}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Nome Completo
              </label>
              <input
                type="text"
                value={formData.nome_completo}
                onChange={(e) => updateField('nome_completo', e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                required
              />
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                CEP
              </label>
              <div className="relative">
                <IMaskInput
                  mask="00000-000"
                  value={formData.cep}
                  unmask={true}
                  onAccept={(value) => updateField('cep', value)}
                  onBlur={handleCepBlur}
                  className="w-full px-4 py-3 pl-10 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                  required
                />
                {loadingCep ? (
                  <Loader2 className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 animate-spin" />
                ) : (
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Endereço
              </label>
              <input
                type="text"
                value={formData.logradouro}
                onChange={(e) => updateField('logradouro', e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Número
                </label>
                <input
                  type="text"
                  value={formData.numero}
                  onChange={(e) => updateField('numero', e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Complemento
                </label>
                <input
                  type="text"
                  value={formData.complemento}
                  onChange={(e) => updateField('complemento', e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                  placeholder="Opcional"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Cidade
                </label>
                <input
                  type="text"
                  value={formData.cidade}
                  onChange={(e) => updateField('cidade', e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Estado
                </label>
                <select
                  value={formData.uf}
                  onChange={(e) => updateField('uf', e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                  required
                >
                  <option value="">Selecione...</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AP">AP</option>
                  <option value="AM">AM</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MT">MT</option>
                  <option value="MS">MS</option>
                  <option value="MG">MG</option>
                  <option value="PA">PA</option>
                  <option value="PB">PB</option>
                  <option value="PR">PR</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RS">RS</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="SC">SC</option>
                  <option value="SP">SP</option>
                  <option value="SE">SE</option>
                  <option value="TO">TO</option>
                </select>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Telefone
              </label>
              <IMaskInput
                mask="(00) 00000-0000"
                value={formData.telefone}
                unmask={true}
                onAccept={(value) => updateField('telefone', value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                E-mail
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => updateField('email', e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Cupom de Desconto (opcional)
              </label>
              <input
                type="text"
                value={formData.cupom}
                onChange={(e) => updateField('cupom', e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-8">
            <div className="bg-gray-50 rounded-xl p-6 space-y-6">
              <div>
                <h3 className="font-medium text-gray-700 mb-2">Tipo de Chip</h3>
                <p className="text-lg">{formData.tipo === 'físico' ? 'Chip Físico' : 'e-SIM'}</p>
              </div>

              <div>
                <h3 className="font-medium text-gray-700 mb-2">Dados Pessoais</h3>
                <p className="text-lg">{formData.nome_completo}</p>
                <p className="text-gray-600">{formData.cpf_cnpj}</p>
              </div>

              <div>
                <h3 className="font-medium text-gray-700 mb-2">Endereço</h3>
                <p className="text-lg">
                  {formData.logradouro}, {formData.numero}
                  {formData.complemento && ` - ${formData.complemento}`}
                </p>
                <p className="text-gray-600">{formData.cidade} - {formData.uf}</p>
                <p className="text-gray-600">{formData.cep}</p>
              </div>

              <div>
                <h3 className="font-medium text-gray-700 mb-2">Contato</h3>
                <p className="text-lg">{formData.telefone}</p>
                <p className="text-gray-600">{formData.email}</p>
              </div>

              {formData.cupom && (
                <div>
                  <h3 className="font-medium text-gray-700 mb-2">Cupom</h3>
                  <p className="text-lg">{formData.cupom}</p>
                </div>
              )}
            </div>
          </div>
        );

      case 5:
        return (
          <div className="space-y-8">
            <div className="bg-green-50 p-8 rounded-xl text-center">
              <div className="flex justify-center mb-6">
                <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
                  <CheckCircle className="w-10 h-10 text-green-600" />
                </div>
              </div>
              
              <h3 className="text-2xl font-bold text-green-800 mb-4">Pedido Realizado com Sucesso!</h3>
              
              <p className="text-gray-700 mb-6">
                Seu pedido foi registrado e está aguardando pagamento. Em até 48 horas após a confirmação do pagamento, nossa equipe entrará em contato para fornecer mais informações sobre seu pedido.
              </p>
              
              <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-gray-600">Número do Pedido:</span>
                  <span className="font-medium">{orderConfirmation?.order_id || 'N/A'}</span>
                </div>
                
                <div className="flex justify-between items-center mb-4">
                  <span className="text-gray-600">Tipo de Chip:</span>
                  <span className="font-medium">{formData.tipo === 'físico' ? 'Chip Físico' : 'e-SIM'}</span>
                </div>
                
                <div className="flex justify-between items-center mb-4">
                  <span className="text-gray-600">Valor Total:</span>
                  <span className="font-bold text-lg">R$ {orderConfirmation?.total.toFixed(2) || '0.00'}</span>
                </div>
              </div>
              
              <a 
                href={orderConfirmation?.payment_url} 
                target="_blank"
                rel="noopener noreferrer"
                className="bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center mx-auto w-full max-w-md"
              >
                Realizar Pagamento
                <ExternalLink className="w-5 h-5 ml-2" />
              </a>
              
              <p className="text-sm text-gray-500 mt-4">
                Um email com os detalhes do pedido foi enviado para {formData.email}
              </p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      {/* Progress Steps */}
      <div className="mb-8">
        <div className="flex justify-between items-center">
          {steps.map((step, index) => (
            <React.Fragment key={step.id}>
              <div className="flex flex-col items-center">
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center border-2 transition-colors ${
                    index <= currentStep
                      ? 'border-uai-teal bg-uai-light text-uai-teal'
                      : 'border-gray-200 text-gray-400'
                  }`}
                >
                  {index + 1}
                </div>
                <div className="text-center mt-2">
                  <p className={`font-medium ${
                    index <= currentStep ? 'text-gray-900' : 'text-gray-400'
                  }`}>
                    {step.title}
                  </p>
                  <p className={`text-sm ${
                    index <= currentStep ? 'text-gray-600' : 'text-gray-400'
                  }`}>
                    {step.subtitle}
                  </p>
                </div>
              </div>
              {index < steps.length - 1 && (
                <div className={`flex-1 h-0.5 mx-4 ${
                  index < currentStep ? 'bg-uai-teal' : 'bg-gray-200'
                }`} />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Form Content */}
      <form onSubmit={handleSubmit}>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            {renderStep()}
          </motion.div>
        </AnimatePresence>

        {/* Navigation Buttons */}
        {currentStep < 5 && (
          <div className="mt-8 flex justify-between">
            {currentStep > 0 && (
              <button
                type="button"
                onClick={prevStep}
                className="flex items-center px-6 py-3 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Voltar
              </button>
            )}
            
            {currentStep < 4 ? (
              <button
                type="button"
                onClick={nextStep}
                className="ml-auto flex items-center px-6 py-3 bg-uai-teal text-white rounded-lg hover:bg-uai-dark transition-colors"
              >
                Próximo
                <ArrowRight className="w-5 h-5 ml-2" />
              </button>
            ) : currentStep === 4 ? (
              <button
                type="submit"
                disabled={loading}
                className="ml-auto flex items-center px-6 py-3 bg-uai-teal text-white rounded-lg hover:bg-uai-dark transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    Processando...
                  </> ) : (
                  <>
                    Finalizar pedido
                    <ArrowRight className="w-5 h-5 ml-2" />
                  </>
                )}
              </button>
            ) : null}
          </div>
        )}
      </form>
    </div>
  );
};

export default ChipRequestForm;