import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Create client with more explicit error handling and timeout settings
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    storage: window.localStorage,
    storageKey: 'supabase.auth.token'
  },
  global: {
    headers: {
      'x-application-name': 'uaisat-website'
    }
  },
  db: {
    schema: 'public'
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  },
  // Add timeout settings
  fetch: (url, options = {}) => {
    return fetch(url, {
      ...options,
      timeout: 30000, // 30 second timeout
    });
  }
});

// Enhanced network connectivity check
const checkNetworkConnectivity = () => {
  return navigator.onLine;
};

// Initialize Supabase connection with enhanced error handling
export const initializeSupabase = async (retries = 5, initialDelay = 1000) => {
  if (!checkNetworkConnectivity()) {
    throw new Error('Sem conexão com a internet. Por favor, verifique sua conexão e tente novamente.');
  }

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      // Test the connection with a simple query first
      const { data: testData, error: testError } = await supabase
        .from('users')
        .select('id')
        .limit(1);

      if (testError) {
        throw testError;
      }

      const { data, error } = await supabase.auth.getSession();
      
      if (error) {
        if (attempt === retries) throw error;
        console.warn(`Tentativa ${attempt}/${retries}: Falha ao inicializar Supabase:`, error);
        await new Promise(resolve => setTimeout(resolve, initialDelay * Math.pow(2, attempt - 1)));
        continue;
      }

      console.log('Conexão Supabase inicializada com sucesso');
      return data;
    } catch (error) {
      if (attempt === retries) {
        console.error('Erro ao inicializar Supabase após múltiplas tentativas:', error);
        throw new Error('Não foi possível estabelecer conexão com o servidor. Por favor, tente novamente em alguns minutos.');
      }
      console.warn(`Tentativa ${attempt}/${retries}: Falha ao inicializar Supabase:`, error);
      await new Promise(resolve => setTimeout(resolve, initialDelay * Math.pow(2, attempt - 1)));
    }
  }
};

// Enhanced sign in with better error handling
export const signInWithRetry = async (
  email: string,
  password: string,
  retries = 3,
  initialDelay = 1000
) => {
  if (!checkNetworkConnectivity()) {
    throw new Error('Sem conexão com a internet. Por favor, verifique sua conexão e tente novamente.');
  }

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      // Test database connection before attempting sign in
      const { error: testError } = await supabase
        .from('users')
        .select('id')
        .limit(1);

      if (testError) {
        console.error('Database connection test failed:', testError);
        throw new Error('Erro de conexão com o banco de dados. Por favor, tente novamente em alguns minutos.');
      }

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: {
          redirectTo: window.location.origin
        }
      });

      if (error) {
        if (error.message.includes('Invalid login credentials')) {
          throw new Error('Email ou senha incorretos.');
        }
        
        if (attempt === retries) throw error;
        console.warn(`Tentativa ${attempt}/${retries}: Falha ao fazer login:`, error);
        await new Promise(resolve => setTimeout(resolve, initialDelay * Math.pow(2, attempt - 1)));
        continue;
      }

      return data;
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('Email ou senha incorretos')) {
          throw error;
        }
        if (error.message.includes('Erro de conexão')) {
          throw error;
        }
      }
      
      if (attempt === retries) {
        console.error('Erro ao fazer login após múltiplas tentativas:', error);
        throw new Error('Não foi possível fazer login. Por favor, tente novamente em alguns minutos.');
      }
      
      console.warn(`Tentativa ${attempt}/${retries}: Falha ao fazer login:`, error);
      await new Promise(resolve => setTimeout(resolve, initialDelay * Math.pow(2, attempt - 1)));
    }
  }
};