import React, { useState } from 'react';
import { 
  Smartphone, 
  Wifi, 
  CheckCircle, 
  Globe, 
  Zap, 
  Shield, 
  Clock, 
  ArrowRight, 
  QrCode,
  X,
  User,
  Phone,
  MessageSquare,
  Music,
  MapPin
} from 'lucide-react';
import { Link } from 'react-router-dom';
import ChipRequestForm from '../components/forms/ChipRequestForm';
import { plans as sitePlans } from '../lib/data';

const EsimSales: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  
  // Filter plans from the site data
  const filteredPlans = sitePlans.filter(plan => plan.active && plan.type === 'controle');
  
  const benefits = [
    {
      icon: <Smartphone className="w-12 h-12 text-uai-teal" />,
      title: 'Ativação Instantânea',
      description: 'Sem espera por entrega física. Ative seu chip virtual em minutos.'
    },
    {
      icon: <Globe className="w-12 h-12 text-uai-teal" />,
      title: 'Cobertura Nacional',
      description: 'Sinal de qualidade em todo o Brasil com tecnologia 4G+ e 5G.'
    },
    {
      icon: <Zap className="w-12 h-12 text-uai-teal" />,
      title: 'Internet Rápida',
      description: 'Navegue com alta velocidade e estabilidade onde estiver.'
    },
    {
      icon: <Shield className="w-12 h-12 text-uai-teal" />,
      title: 'Segurança Total',
      description: 'Tecnologia avançada contra clonagem e fraudes.'
    }
  ];

  const steps = [
    {
      number: '01',
      title: 'Solicite seu e-SIM',
      description: 'Preencha o formulário com seus dados e escolha o plano ideal para você.'
    },
    {
      number: '02',
      title: 'Faça o pagamento',
      description: 'Escolha a forma de pagamento que preferir: cartão, boleto ou PIX.'
    },
    {
      number: '03',
      title: 'Receba seu QR Code',
      description: 'Após a confirmação do pagamento, você receberá o QR Code por e-mail.'
    },
    {
      number: '04',
      title: 'Ative seu e-SIM',
      description: 'Escaneie o QR Code com seu celular e siga as instruções para ativar.'
    }
  ];

  const testimonials = [
    {
      name: 'Carlos Silva',
      location: 'São Paulo, SP',
      comment: 'Processo super rápido! Em menos de 10 minutos já estava com meu e-SIM ativado e funcionando perfeitamente.',
      rating: 5
    },
    {
      name: 'Ana Oliveira',
      location: 'Rio de Janeiro, RJ',
      comment: 'Melhor decisão que tomei. Internet rápida, sem quedas e o preço é excelente comparado às outras operadoras.',
      rating: 5
    },
    {
      name: 'Roberto Almeida',
      location: 'Belo Horizonte, MG',
      comment: 'Atendimento nota 10! Tive uma dúvida na ativação e o suporte me ajudou na hora. Recomendo demais!',
      rating: 4
    }
  ];

  const faqs = [
    {
      question: 'O que é um e-SIM?',
      answer: 'e-SIM é um chip virtual que substitui o chip físico tradicional. Ele é incorporado diretamente ao seu smartphone, permitindo que você ative uma linha sem precisar de um chip físico.'
    },
    {
      question: 'Meu celular é compatível com e-SIM?',
      answer: 'A maioria dos smartphones lançados a partir de 2018 são compatíveis com e-SIM, incluindo iPhone XS ou superior, Samsung Galaxy S20 ou superior, Google Pixel 3 ou superior, entre outros. Consulte o site do fabricante para confirmar a compatibilidade do seu modelo.'
    },
    {
      question: 'Como ativo meu e-SIM?',
      answer: 'Após a compra, você receberá um QR Code por e-mail. Basta ir nas configurações do seu celular, selecionar a opção de adicionar plano celular ou e-SIM, escanear o QR Code e seguir as instruções na tela.'
    },
    {
      question: 'Posso manter meu número atual?',
      answer: 'Sim! Você pode solicitar a portabilidade do seu número atual para o e-SIM UaiSat. O processo é gratuito e leva em média 3 dias úteis para ser concluído.'
    },
    {
      question: 'Quanto tempo leva para ativar o e-SIM?',
      answer: 'Após a confirmação do pagamento, você receberá o QR Code em até 30 minutos. A ativação do e-SIM no seu celular leva apenas alguns minutos.'
    }
  ];

  const handlePlanSelect = (planId: string) => {
    setSelectedPlan(planId);
    setShowForm(true);
  };

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-uai-dark via-uai-teal to-uai-dark opacity-90"></div>
          <img 
            src="https://images.unsplash.com/photo-1556656793-08538906a9f8?auto=format&fit=crop&q=80&w=1470" 
            alt="e-SIM Background" 
            className="w-full h-full object-cover mix-blend-overlay"
          />
        </div>
        
        <div className="container mx-auto px-4 py-20 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-white">
              <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white mb-6">
                <span className="flex items-center">
                  <QrCode className="w-5 h-5 mr-2" />
                  Tecnologia e-SIM UaiSat
                </span>
              </div>
              
              <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
                Chip Virtual <span className="text-yellow-300">Pré-Controle</span> e Ativação Instantânea
              </h1>
              
              <p className="text-xl md:text-2xl mb-8 text-white/90 leading-relaxed">
                Tenha uma linha de celular digital com internet 4G/5G, WhatsApp ilimitado e ligações grátis para qualquer operadora. Ative em minutos!
              </p>
              
              <div className="flex flex-wrap gap-4 mb-8">
                <button 
                  onClick={() => setShowForm(true)}
                  className="bg-white text-uai-teal hover:bg-gray-100 px-8 py-4 rounded-xl font-bold text-lg transition-all duration-300 flex items-center group"
                >
                  <span>Quero meu e-SIM</span>
                  <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300" />
                </button>
                
                <a 
                  href="#planos"
                  className="bg-transparent border-2 border-white text-white hover:bg-white/10 px-8 py-4 rounded-xl font-bold text-lg transition-all duration-300"
                >
                  Ver planos
                </a>
              </div>
              
              <div className="flex flex-wrap items-center gap-6">
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-yellow-300 mr-2" />
                  <span>Ativação em minutos</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-yellow-300 mr-2" />
                  <span>Sem fidelidade</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-yellow-300 mr-2" />
                  <span>Cobertura nacional</span>
                </div>
              </div>
            </div>
            
            <div className="hidden lg:block">
              <div className="relative">
                <img 
                  src="https://images.unsplash.com/photo-1611162618071-b39a2ec055fb?auto=format&fit=crop&q=80&w=1374"
                  alt="e-SIM Smartphone" 
                  className="rounded-3xl shadow-2xl transform hover:scale-105 transition-transform duration-700 ease-in-out"
                />
                <div className="absolute -bottom-10 -left-10 bg-white p-6 rounded-2xl shadow-xl">
                  <div className="flex items-center">
                    <div className="bg-green-100 p-3 rounded-full mr-4">
                      <Clock className="w-8 h-8 text-green-600" />
                    </div>
                    <div>
                      <p className="text-gray-500 text-sm">Tempo de ativação</p>
                      <p className="font-bold text-xl">5 minutos</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 px-4">
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Por que escolher o e-SIM UaiSat?</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Descubra as vantagens exclusivas do chip virtual que está revolucionando a telefonia móvel no Brasil
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index}
                className="bg-white p-8 rounded-2xl shadow-card hover:shadow-xl transition-all duration-300 group hover:-translate-y-2 border border-gray-100"
              >
                <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                  {benefit.icon}
                </div>
                <h3 className="text-2xl font-bold mb-3">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Plans Section */}
      <section id="planos" className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Escolha o plano ideal para você</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Planos flexíveis com internet de alta velocidade, ligações ilimitadas e muito mais
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {filteredPlans.map((plan, index) => (
              <div 
                key={index}
                className={`bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-500 transform hover:-translate-y-2 group relative ${
                  index === 1 ? 'md:-mt-4 md:mb-4 ring-4 ring-uai-teal' : ''
                }`}
              >
                {index === 1 && (
                  <div className="absolute top-0 left-0 right-0 bg-uai-teal text-white py-2 text-center font-bold">
                    MAIS POPULAR
                  </div>
                )}
                
                <div className={`bg-gradient-to-r from-uai-teal to-uai-dark text-white p-6 ${index === 1 ? 'pt-10' : ''}`}>
                  <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                  <div className="flex items-baseline">
                    <span className="text-lg opacity-80">R$</span>
                    <span className="text-5xl font-bold mx-1">{plan.price.toFixed(2).split('.')[0]}</span>
                    <span className="text-xl opacity-80">,{plan.price.toFixed(2).split('.')[1]}</span>
                    <span className="text-sm opacity-80 ml-1">/mês</span>
                  </div>
                </div>
                
                <div className="p-6">
                  <div className="space-y-6">
                    <div className="flex items-center bg-uai-light p-4 rounded-xl">
                      <Zap className="w-6 h-6 text-uai-teal mr-3" />
                      <div>
                        <p className="font-semibold text-lg">{plan.data}</p>
                        <p className="text-sm text-gray-600">de internet 4G/5G</p>
                      </div>
                    </div>
                    
                    <div className="space-y-3">
                      <div className="flex items-center">
                        <MessageSquare className="w-5 h-5 text-uai-teal mr-3" />
                        <span>WhatsApp ilimitado</span>
                      </div>
                      
                      <div className="flex items-center">
                        <MapPin className="w-5 h-5 text-uai-teal mr-3" />
                        <span>Waze ilimitado</span>
                      </div>
                      
                      <div className="flex items-center">
                        <Phone className="w-5 h-5 text-uai-teal mr-3" />
                        <span>{plan.calls}</span>
                      </div>
                    </div>
                    
                    <div className="border-t border-gray-100 pt-4">
                      <div className="flex items-center mb-3">
                        <Shield className="w-5 h-5 text-uai-teal mr-2" />
                        <p className="font-semibold">Benefícios inclusos:</p>
                      </div>
                      <ul className="space-y-2">
                        <li className="flex items-start">
                          <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-2 mt-0.5 flex-shrink-0">
                            <CheckCircle className="w-3 h-3 text-green-600" />
                          </div>
                          <span className="text-sm text-gray-700">WhatsApp ilimitado</span>
                        </li>
                        <li className="flex items-start">
                          <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-2 mt-0.5 flex-shrink-0">
                            <CheckCircle className="w-3 h-3 text-green-600" />
                          </div>
                          <span className="text-sm text-gray-700">Waze ilimitado</span>
                        </li>
                        <li className="flex items-start">
                          <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-2 mt-0.5 flex-shrink-0">
                            <CheckCircle className="w-3 h-3 text-green-600" />
                          </div>
                          <span className="text-sm text-gray-700">Skeelo Light incluso</span>
                        </li>
                        <li className="flex items-start">
                          <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-2 mt-0.5 flex-shrink-0">
                            <CheckCircle className="w-3 h-3 text-green-600" />
                          </div>
                          <span className="text-sm text-gray-700">Sem fidelidade</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  <button 
                    onClick={() => handlePlanSelect(plan.id)}
                    className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-6 rounded-xl mt-6 transition-all duration-300 flex items-center justify-center group"
                  >
                    <span className="mr-2">Escolher este plano</span>
                    <ArrowRight className="w-5 h-5 transform transition-transform duration-300 group-hover:translate-x-1" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-12 bg-uai-light rounded-2xl p-8 max-w-4xl mx-auto">
            <div className="flex flex-col md:flex-row items-center">
              <div className="mb-6 md:mb-0 md:mr-8 md:w-2/3">
                <h3 className="text-2xl font-bold mb-2">Não encontrou o plano ideal?</h3>
                <p className="text-gray-600">
                  Temos outras opções disponíveis para atender às suas necessidades. Entre em contato conosco para conhecer todos os nossos planos.
                </p>
              </div>
              <div className="md:w-1/3">
                <Link 
                  to="/contato"
                  className="bg-uai-teal hover:bg-uai-dark text-white font-bold py-3 px-6 rounded-xl transition-all duration-300 flex items-center justify-center w-full"
                >
                  <span>Falar com consultor</span>
                  <ArrowRight className="ml-2 w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">Tudo o que você precisa em um único plano</h2>
              
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="bg-uai-light p-3 rounded-lg mr-4">
                    <Wifi className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Internet de Alta Velocidade</h3>
                    <p className="text-gray-600">
                      Navegue com velocidade 4G+ e 5G nas principais capitais. Streaming, redes sociais e trabalho sem interrupções.
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="bg-uai-light p-3 rounded-lg mr-4">
                    <MessageSquare className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">WhatsApp Ilimitado</h3>
                    <p className="text-gray-600">
                      Use o WhatsApp à vontade para mensagens, fotos e áudios sem consumir seu pacote de dados.
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="bg-uai-light p-3 rounded-lg mr-4">
                    <MapPin className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Waze Ilimitado</h3>
                    <p className="text-gray-600">
                      Navegue com o Waze sem consumir seu pacote de dados, economizando internet para o que realmente importa.
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="bg-uai-light p-3 rounded-lg mr-4">
                    <Phone className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Ligações Ilimitadas</h3>
                    <p className="text-gray-600">
                      Fale à vontade com qualquer operadora em todo o Brasil sem se preocupar com custos adicionais.
                    </p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="bg-uai-light p-3 rounded-lg mr-4">
                    <Music className="w-6 h-6 text-uai-teal" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Skeelo Light</h3>
                    <p className="text-gray-600">
                      Acesso ao aplicativo de audiobooks Skeelo Light incluso em todos os planos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="relative">
              <img 
                src="https://images.unsplash.com/photo-1586953208448-b95a79798f07?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                alt="Smartphone com e-SIM" 
                className="rounded-3xl shadow-2xl"
              />
              <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-2xl shadow-xl">
                <div className="flex items-center">
                  <div className="bg-uai-light p-3 rounded-full mr-4">
                    <Smartphone className="w-8 h-8 text-uai-teal" />
                  </div>
                  <div>
                    <p className="text-gray-500 text-sm">Compatível com</p>
                    <p className="font-bold text-xl">iPhone e Android</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-20 px-4">
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Como funciona</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Processo simples e rápido para você começar a usar seu e-SIM em poucos minutos
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-5xl mx-auto">
            {steps.map((step, index) => (
              <div key={index} className="relative">
                {/* Step number */}
                <div className="absolute -top-4 -left-4 w-12 h-12 rounded-full bg-uai-teal text-white flex items-center justify-center font-bold text-xl">
                  {step.number}
                </div>
                
                {/* Step content */}
                <div className="bg-white p-8 pt-10 rounded-2xl shadow-card border border-gray-100 h-full">
                  <h3 className="text-xl font-bold mb-3">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                
                {/* Connector line */}
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-1/2 -right-4 w-8 h-0.5 bg-gray-300"></div>
                )}
              </div>
            ))}
          </div>
          
          <div className="text-center mt-12">
            <button 
              onClick={() => setShowForm(true)}
              className="bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 inline-flex items-center"
            >
              <span>Solicitar meu e-SIM agora</span>
              <ArrowRight className="ml-2 w-5 h-5" />
            </button>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">O que nossos clientes dizem</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Veja a experiência de quem já está usando o e-SIM UaiSat
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {testimonials.map((testimonial, index) => (
              <div 
                key={index}
                className="bg-white p-8 rounded-2xl shadow-card hover:shadow-xl transition-all duration-300 relative"
              >
                {/* Quote icon */}
                <div className="absolute -top-4 -right-4 bg-uai-teal text-white p-3 rounded-full shadow-lg">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 11H6C5.46957 11 4.96086 10.7893 4.58579 10.4142C4.21071 10.0391 4 9.53043 4 9V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H8C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7V15C10 16.0609 9.57857 17.0783 8.82843 17.8284C8.07828 18.5786 7.06087 19 6 19H5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 11H16C15.4696 11 14.9609 10.7893 14.5858 10.4142C14.2107 10.0391 14 9.53043 14 9V7C14 6.46957 14.2107 5.96086 14.5858 5.58579C14.9609 5.21071 15.4696 5 16 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V15C20 16.0609 19.5786 17.0783 18.8284 17.8284C18.0783 18.5786 17.0609 19 16 19H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                
                {/* Rating stars */}
                <div className="flex mb-4">
                  {[...Array(5)].map((_, i) => (
                    <svg 
                      key={i} 
                      className={`w-5 h-5 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`} 
                      fill="currentColor" 
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>
                
                {/* Testimonial content */}
                <p className="text-gray-700 mb-6 italic">"{testimonial.comment}"</p>
                
                {/* User info */}
                <div className="flex items-center">
                  <div className="bg-gray-100 rounded-full w-12 h-12 flex items-center justify-center mr-4">
                    <User className="w-6 h-6 text-gray-500" />
                  </div>
                  <div>
                    <p className="font-bold">{testimonial.name}</p>
                    <p className="text-gray-500 text-sm">{testimonial.location}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 px-4">
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Perguntas Frequentes</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Tire suas dúvidas sobre o e-SIM UaiSat
            </p>
          </div>
          
          <div className="max-w-3xl mx-auto">
            <div className="space-y-6">
              {faqs.map((faq, index) => (
                <div key={index} className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
                  <details className="group">
                    <summary className="flex items-center justify-between p-6 cursor-pointer">
                      <h3 className="text-lg font-semibold pr-8">{faq.question}</h3>
                      <span className="transition-transform duration-300 group-open:rotate-180">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </span>
                    </summary>
                    <div className="px-6 pb-6 text-gray-600">
                      <p>{faq.answer}</p>
                    </div>
                  </details>
                </div>
              ))}
            </div>
          </div>
          
          <div className="text-center mt-12">
            <p className="text-gray-600 mb-6">Ainda tem dúvidas?</p>
            <Link 
              to="/contato"
              className="text-uai-teal hover:text-uai-dark font-medium transition-colors"
            >
              Entre em contato com nosso suporte
            </Link>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-4 bg-gradient-to-r from-uai-dark to-uai-teal text-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">Pronto para experimentar o e-SIM UaiSat?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Ative seu chip virtual em minutos e comece a aproveitar todos os benefícios da UaiSat
          </p>
          <button 
            onClick={() => setShowForm(true)}
            className="bg-white text-uai-teal hover:bg-gray-100 font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 inline-flex items-center"
          >
            <span>Solicitar meu e-SIM agora</span>
            <ArrowRight className="ml-2 w-5 h-5" />
          </button>
        </div>
      </section>

      {/* Form Modal */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-2xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-white p-4 flex justify-between items-center border-b z-10">
              <h2 className="text-2xl font-bold">Solicitar e-SIM UaiSat</h2>
              <button 
                onClick={() => setShowForm(false)}
                className="text-gray-500 hover:text-gray-700 p-2"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            <div className="p-6">
              <ChipRequestForm 
                onSuccess={() => {
                  // Form will handle success internally
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EsimSales;